<template>
  <div class="page">
    <el-scrollbar class="Yscrollbar" style="height: calc(100% - 8vh)">
      <LabelContent title="互助内容审核" ref="LabelContent"></LabelContent>
      <el-form :inline="true" class="arrange-form" size="small" style="text-align: left">
        <el-col :span="6">
          <el-form-item style="margin-left: 1vw" label="发布人：">
            <p>{{ items.creatorName }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item style="margin-left: 1vw" label="发布时间">
            <p>{{ items.createTime }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item style="margin-left: 1vw" label="互助内容：">
            <p>{{ items.content }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item style="margin-left: 1vw" label="互助图片/视频：">
            <div v-if="items.hasOwnProperty('files')" class="flex">
              <div v-for="item in items.files" :key="item.updId">
                <el-image :preview-src-list="items['srcList']" :src="item.updUrl"></el-image>
              </div>
            </div>
            <img v-else style="width: 10vw; height: 10vw" src="../../assets/imgs/diushi.png" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item style="margin-left: 1vw" label="地址信息：">
            <p>{{ items.address }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="审核：" style="margin-left: 1vw" class="stywid stywid1">
            <el-radio v-model="Submitquery.auditStatus" label="success" value="success"
              >通过</el-radio
            >
            <el-radio v-model="Submitquery.auditStatus" label="fail" value="fail">驳回</el-radio>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item style="margin-left: 1vw" label="备注:">
            <el-input
              :rows="3"
              :cols="1"
              placeholder="请输入内容"
              v-model="Submitquery.remark"
              type="textarea"
            />
          </el-form-item>
        </el-col>
      </el-form>
    </el-scrollbar>
    <div class="bottomBtnBox">
      <el-button class="long" @click="submit" type="primary" size="small">提交</el-button>
      <el-button class="long" @click="cancel" type="info" size="small">取消</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "TestPage_detail",
  data () {
    return {
      items: {},
      Submitquery: {
        artId: 0,
        auditStatus: "success",
        remark: "",
      },
    }
  },
  mounted () {
    let { artId } = this.$route.params
    this.Submitquery.artId = artId
    this.init()
  },
  methods: {
    async init () {
      let { data } = await this.$http
        .request({
          url: "/hw/portal/articles/mi/detail/" + this.Submitquery.artId,
          method: "GET",
          showLoading: true,
        })
      if (data.hasOwnProperty('files')) {
        let srcList = data.files.map(x => x.updUrl)
        data["srcList"] = srcList
      }
      this.items = data
    },
    async submit () {
      let data = await this.$http
        .request({
          url: "/hw/admin/articles/mutual_information/audit/" + this.Submitquery.artId,
          method: "POST",
          params: this.Submitquery,
          showLoading: true,
        })
      if (data.status == 100) {
        this.$message({
          message: '审核成功',
          type: 'success'
        })

        this.jump("/HelpControl")
      }
    },
    cancel () {
      this.jump("/HelpControl")
    },
  },
};
</script>

<style lang="scss" scoped>
.el-col-6 {
  width: 100%;
}
</style>
